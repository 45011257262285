@font-face {
  font-family: Quicksand-var;
  src: url("Quicksand-Variable.5039d807.woff2") format("woff2"), url("Quicksand-Variable.4eb80d06.woff") format("woff"), url("Quicksand-Variable.b05f95e5.ttf") format("truetype");
  font-weight: 300 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Quicksand;
  src: url("Quicksand-Light.529ba1ef.woff2") format("woff2"), url("Quicksand-Light.93e67746.woff") format("woff"), url("Quicksand-Light.593efde3.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Quicksand;
  src: url("Quicksand-Regular.f3872010.woff2") format("woff2"), url("Quicksand-Regular.47aaff80.woff") format("woff"), url("Quicksand-Regular.d98513b5.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Quicksand;
  src: url("Quicksand-Medium.2fc59039.woff2") format("woff2"), url("Quicksand-Medium.7cb4a124.woff") format("woff"), url("Quicksand-Medium.bcbb935a.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Quicksand;
  src: url("Quicksand-SemiBold.60d31b2e.woff2") format("woff2"), url("Quicksand-SemiBold.029e68d2.woff") format("woff"), url("Quicksand-SemiBold.6d267c5c.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Quicksand;
  src: url("Quicksand-Bold.cd7b65da.woff2") format("woff2"), url("Quicksand-Bold.824eeda0.woff") format("woff"), url("Quicksand-Bold.2f616021.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

/*# sourceMappingURL=index.1dfe27ff.css.map */
