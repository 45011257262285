/**
 * @license
 *
 * Font Family: Quicksand
 * Designed by: Andrew Paglinawan
 * URL: https://www.fontshare.com/fonts/quicksand
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Quicksand Variable(Variable font)
 * Quicksand Light
 * Quicksand Regular
 * Quicksand Medium
 * Quicksand Semi Bold
 * Quicksand Bold
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 300.0;
*
* available axes:

* 'wght' (range from 300.0 to 700.0)

*/

@font-face {
  font-family: 'Quicksand-var';
  src: url('./Quicksand-Variable.woff2') format('woff2'),
    url('./Quicksand-Variable.woff') format('woff'),
    url('./Quicksand-Variable.ttf') format('truetype');
  font-weight: 300 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./Quicksand-Light.woff2') format('woff2'),
    url('./Quicksand-Light.woff') format('woff'),
    url('./Quicksand-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./Quicksand-Regular.woff2') format('woff2'),
    url('./Quicksand-Regular.woff') format('woff'),
    url('./Quicksand-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./Quicksand-Medium.woff2') format('woff2'),
    url('./Quicksand-Medium.woff') format('woff'),
    url('./Quicksand-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./Quicksand-SemiBold.woff2') format('woff2'),
    url('./Quicksand-SemiBold.woff') format('woff'),
    url('./Quicksand-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./Quicksand-Bold.woff2') format('woff2'),
    url('./Quicksand-Bold.woff') format('woff'),
    url('./Quicksand-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
